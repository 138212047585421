// @import "/src/sass/bootstrap-essentials/overrides/variables"; // Overrides bootstrap default variables
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@mixin pseudo($display: block, $pos: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $pos;
}

@mixin flex-center() {
    display:flex;
    justify-content:center;
    align-items:center;
}

@mixin absolute-center() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin absolute-center-x() {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@mixin absolute-center-y() {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}


// =============================================================================
// Font Face / https://gist.github.com/jonathantneal/d0460e5c2d5d7f9bc5e6
// =============================================================================


@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

@import '/src/styles/abstracts';

/* Application-wide Styles */

html, body { height: 100%; }

body {
  margin: 0;
  padding:0;
  font-family: var(--font-default);
  color: var(--color-primary);
  background-color: var(--bg-default);
}

html.no-scroll {
  body {
    //position: fixed;
    margin: 0 !important;
    // min-height: 100%;
    width: 100%;
    overflow: hidden;
  }
}

#wrapper {
  font-size: var(--font-size-default);
}

#layout-content {
  width: 100%;
  padding-top: var(--header-height);
  min-height: calc(100vh - var(--footer-height));

  @include media-breakpoint-down(sm) {
    padding-top: var(--header-height-mobile);
    min-height: 0;
  }
}

#header {
  position: fixed;
  z-index: 100;
  top:0;
  left: 0;
  width: 100%;
  max-width: 100vw;
}

a {
  color: var(--color-secondary);
  &:hover {
    color: var(--color-secondary-darker);
  }
}

.page-content {
  padding: 50px 0;

  @include media-breakpoint-down(sm) {
    padding: 30px 0;
  }
}

.precheckin-card {
  background: var(--bg-content);
  border-radius: var(--b-radius-default);
  box-shadow: var(--b-shadow);
  padding: 20px;
  max-width:90%;

  &.card-xs {
    width: 300px;
  }
  &.card-sm {
    width: 500px;
  }
  &.card-md {
    width: 800px;
  }
  &.card-lg {
    width: 1200px;
  }
}

.pre-checkin .precheckin-steps-navigation {
  padding:0 15px 50px 15px!important;
}

.common-navigation {
  display: flex;
  width: 100%;
  justify-content: center;
  padding:50px 10px 0;
  gap: 20px;

  button {
    &:first-child {order: 2;}
    &:last-child {order: 1;}
  }

  @include media-breakpoint-down(sm) {
    display: block;
    button {margin: auto;}
    button:first-child {
      margin-bottom: 20px;
    }
  }
}

.br-border-default {
  border-radius: var(--b-radius-default);
  border: 1px solid var(--color-alt-200);
}

button.no-style {
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
}



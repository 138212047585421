@import '/src/styles/abstracts/mixins';

// non-theme specific variables
$black: #000;
$white: #fff;

$header-height: 100px;
$header-height-mobile: 90px;
$footer-height: 52px;

$head-pictogram-height: 116px;
$head-pictogram-height-mobile: 56px;

:root {
  --header-height: #{$header-height};
  --header-height-mobile: #{$header-height-mobile};
  --footer-height: #{$footer-height};
}



.mat-form-field {
  color: var(--color-secondary);
}

.mb-0 {
  .mat-form-field-wrapper {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

// Outline appearance
.mat-form-field-appearance-outline {
  .mat-form-field-outline {
    color: var(--color-primary-alt);
  }

  &.mat-focused {
    .mat-form-field-outline-thick,
    .mat-form-field-label {
      color: var(--color-primary);
    }
  }
}

// Mat Selects
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--color-secondary);
}

// Custom arrow down on all mat-select
.mat-select-arrow {
  border: 0!important;
  width:12px!important;
  height:12px!important;
  background-image: url("/assets/img/icons/icon-chevron-down.svg");
  background-size: cover;
}

// Custom mat-select with theme-compatible arrow color
// Example : <mat-select-trigger> inside lang-select component
.mat-select-custom-arrow-trigger {
  .mat-select-value {
    max-width: 100%;
  }
  .mat-select-arrow-wrapper {
    display: none;
  }

  .trigger-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .trigger-arrow {
      width:20px;
      display: flex;
      justify-content: center;
    }
  }
}

// Lang Select Component
.lang-select-container {
  .mat-form-field-infix {
    width:60px;
  }
  .mat-form-field-underline {
    display: none;
  }
}
.lang-select-panel{
  min-width: 0!important;
}


// Precheckin Snackbar {
.precheckin-snackbar {
  &.mat-snack-bar-container {
    max-width: calc(100vw - 48px);
    border-radius: 6px;
    padding-right: 0;

    &.success {
      background: #e4f8f0;
      border-left: 6px solid #1ea97c;
      color: #1ea97c;
    }

    &.info {
      background: #e9e9ff;
      border-left: 6px solid #696cff;
      color: #696cff;
    }

    &.warning {
      background: #fff2e2;
      border-left: 6px solid #cc8925;
      color: #cc8925;
    }

    &.error {
      background: #ffe7e6;
      border-left: 6px solid #ff5757;
      color: #ff5757;
    }

    &.precheckin-lg {
      width: calc(100vw - 48px);
      margin:24px;
    }
  }
}

// Precheckin Guest Infos Details

.guest-infos-form {
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

// Mat Slide Toggles
.mat-slide-toggle.mat-checked {
  .mat-slide-toggle-thumb {
    background: var(--color-secondary);
  }
  .mat-slide-toggle-bar {
    background: var(--color-secondary-transparent);
  }
}

// Mat Checkboxes
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, 
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, 
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: var(--color-secondary);
}

.cgv-checkbox {
  .mat-checkbox-layout {
    white-space: normal;
  }
  .mat-checkbox-inner-container {
    margin: 2px 8px auto 0;
  }
}

.error {
  .mat-checkbox-frame {
    border-color: var(--color-error);
  }
  .mat-checkbox-label {
    &, a {
      color: var(--color-error);
    }
  }
}

// Mat Datepicker
.mat-datepicker-toggle-active {
  color: var(--color-secondary);
}

.mat-calendar-body-selected {
  background-color: var(--color-secondary);
}


.adyen-checkout__payment-method--selected {
  background: none;
  border: 1px solid var(--color-alt-200);
  // border: none;
}

.adyen-checkout__button {
  background: var(--color-secondary);
  border-radius: var(--b-radius-default);

  &:hover {
    background: var(--color-secondary-darker); 
    box-shadow: none;
  }

  &:disabled {
    background: var(--color-primary-disabled);
  }
}

.adyen-checkout__payment-method__brands {
  .adyen-checkout__payment-method__image__wrapper {
    &, img {
      width: 40px;
      height: 26px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .adyen-checkout__payment-method__header__title {
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;

    .adyen-checkout__payment-method__image__wrapper {
      display: none;
      &, img {
        width: calc(40px * .7);
        height: calc(26px * .7);
      }
    }
  }
  .adyen-checkout__payment-method__brands {
    width: 100%;
    justify-content: center;
    gap: 10px;
    .adyen-checkout__payment-method__image__wrapper {
      justify-content: center;
    }
  }
}


.adyen-checkout__checkbox__input:checked + .adyen-checkout__checkbox__label:after {
  background-color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
}

.adyen-checkout__checkbox__input {
  &:checked:hover,
  &:focus {
    + .adyen-checkout__checkbox__label:after {
      border-color: var(--color-secondary);
      box-shadow: 0 0 0 2px var(--color-secondary-transparent);
    }
  }
}

.adyen-checkout__payment-method__radio--selected {
  background-color: var(--color-secondary);

  &:hover {
    box-shadow: 0 0 0 2px var(--color-secondary-transparent);
  }
}

.adyen-checkout__input:not(.adyen-checkout__input--invalid) {
  border-color:  var(--color-alt-200);
}
.adyen-checkout__label--focused {
  .adyen-checkout__label__text {
    color: var(--color-secondary);
  }
}

.adyen-checkout__input--focus, 
.adyen-checkout__input--focus:hover, 
.adyen-checkout__input:active, 
.adyen-checkout__input:active:hover, 
.adyen-checkout__input:focus, 
.adyen-checkout__input:focus:hover {
  border-color: var(--color-secondary);
  box-shadow: 0 0 0 2px var(--color-secondary-transparent);
}

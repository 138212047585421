@import '../abstracts';

[class^="btn-checkin"],
[class*=" btn-checkin"] {
  @include flex-center();
  min-height: 50px;
  padding: 5px 20px;
  border-radius: var(--b-radius-default);
  font-size: var(--font-size-sm);
  font-weight: 500;
  text-decoration: none;
  transition: all .3s ease 0s;
}

.btn-checkin {
  background: var(--color-secondary);
  color: var(--color-text-light);
  border: 2px solid var(--color-secondary);

  &:hover {
    background: var(--color-secondary-darker);
    border-color: var(--color-secondary-darker);
    color: var(--color-text-light);
  }

  &:disabled,
  &.disabled {
    color: var(--color-text-light);
    background: var(--color-primary-disabled);
    border-color: var(--color-primary-disabled);
  }
}

.btn-checkin-white {
  background: var(--color-text-light);
  color: var(--color-primary);
  border: 2px solid var(--color-text-light);

  &:hover {
    border-color: var(--color-secondary);
  }

  &:disabled,
  &.disabled {
    color: var(--color-primary-disabled);
    border-color: var(--color-text-light);
  }
}

.btn-checkin-grey {
  background: var(--color-alt-100);
  color: var(--color-primary);
  border: 2px solid var(--color-alt-100);

  &:hover {
    border-color: var(--color-primary);
  }

  &:disabled {
    color: var(--color-primary-disabled);
    border-color: var(--color-alt-100);
  }
}

.btn-checkin-error {
  background: var(--color-error);
  color: var(--color-text-light);
  border: 2px solid var(--color-error);

  &:hover {
    background: var(--color-error);
    color: var(--color-text-light);
  }

  &:disabled {
    color: var(--color-primary-disabled);
    border-color: var(--color-alt-100);
  }
}

.btn-checkin-success {
  background: var(--color-success);
  color: var(--color-text-light);
  border: 2px solid var(--color-success);
  
  &:hover {
    background: var(--color-success);
    color: var(--color-text-light);
  }

  &:disabled {
    color: var(--color-primary-disabled);
    border-color: var(--color-alt-100);
  }
}